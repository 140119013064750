import React from 'react';
import { BackdropMain } from './Component';

const Backdrop = ({ show, clicked, overflow, children }) => (
    show ? <BackdropMain 
                onClick={clicked}
                overflow={overflow}
            >
                {children}
            </BackdropMain> : null
)

export default Backdrop