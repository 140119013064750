import styled from 'styled-components';
import { deviceLessThan, deviceGreaterThan } from '../../../styles/breakpoints';

export const ModalMain = styled.div`
    transform:${props => props.transform ? "translateY(0)":"translateY(-100vh)"};
    opacity:${props => props.opacity ? 1 : 0};
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    border: none;
    padding: 16px;
    left: 0%;
    top: 0%;
    box-sizing: border-box;
    transition: all 0.3s ease;
`;

export const ModalContainer = styled.div`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "white"};
    position: absolute;
    top: ${props => props.top ? props.top: "181px" };
    bottom:${props => props.bottom ? props.bottom: 'unset'};
    left: ${props => props.left ? props.left: "15%" };
    right: ${props => props.right};
    transform: ${props => props.transform ? props.transform: "translate(-11%, 0%)" };
    width: ${props => props.width ? props.width : "80%"};
    margin:${props => props.margin };
    max-width:${props => props.maxWidth ? props.maxWidth : "max-content"};
    height: auto;
    padding:${props => props.padding ? props.padding : "1px"};
    border-radius:${props => props.borderRadius ? props.borderRadius : "6px"};
    @media ${deviceLessThan.laptop} {
        width: ${props => props.mWidth ? props.mWidth : "80%"};
        transform: ${props => props.mTransform};
    }
    @media ${deviceLessThan.tablet} {
        width: ${props => props.sWidth ? props.sWidth : "95%"};
        bottom:${props => props.sBottom};
        top: ${props => props.sTop ? props.sTop: "181px" };
        left: ${props => props.sLeft ? props.sLeft: "15%" };
        right: ${props => props.sRight};
        transform: ${props => props.sTransform ? props.sTransform: "translate(-11%, 0%)" };
    }
`;

export const ModalWrapper = styled.div`
    display: ${props => props.display ? props.display : "flex"};
    justify-content: center;
    flex-wrap: wrap;
    margin:${props => props.margin ? props.margin : "10px 0px 0px"};
    background:${props => props.background};
    border-radius:${props => props.borderRadius};
    &:hover a{
        text-decoration:none;
    }
`;
