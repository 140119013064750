import React from 'react';
import { PressCardWrapper, PressReleaseContainer } from './Component'
import Text from '../common/ui/Text';
import Tag from '../common/ui/Tag';
import { onButtonClick } from '../../utils/helpers';

const PressCard = ({ tag, title, date, link, data }) => {

    return (
        <PressCardWrapper>
            <PressReleaseContainer onClick={onButtonClick.bind(null, link)}>
                {tag && <Tag
                    size="xs"
                    weight="large"
                    color="TAG"
                    lineHeight="normal"
                    width="max-content"
                    padding="7px 8px 5px 10px"
                    margin="0px 15px 10px 0px"
                    backgroundColor="rgb(232 46 47 / 0.05)"
                >
                    {tag}
                </Tag>}
                {title && <Text
                    size="xxl"
                    align={data == false ? "center" : "left"}
                    weight="large"
                    lineHeight= "1.67"
                    letterSpacing= "-0.23px"
                    padding="0px"
                >
                    {title}
                </Text>}
                {date && <Text
                    size="sm"
                    color="MEDIUM_BLACK"
                    weight="medium"
                    margin="13px 0px 0px"
                >
                    {date}
                </Text>}
            </PressReleaseContainer>
        </PressCardWrapper>
    )
}

export default PressCard;