import styled from 'styled-components';

export const BackdropMain = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background: rgba(17, 17, 17, 0.4);
    opacity: 1;
    overflow-y:${props => props.overflow};
`;