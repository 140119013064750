import styled from 'styled-components';
import { deviceLessThan } from '../../styles/breakpoints';

export const PressContainer = styled.div`
    margin:0 auto;
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 77px 300px;
        position: absolute;
        bottom: -150px;
        right: 0px;
        height: 300px;
        width: 77px;
        z-index: -1;
    };
    &::before{
        content: "";
        background:url(${props => props.backgroundBefore}) no-repeat;
        background-size: 60px 330px;
        position: absolute;
        top: 10px;
        right: 15%;
        transform: rotate(90deg);
        height: 321px;
        width: 171px;
        z-index: -1;
    };
    @media ${deviceLessThan.laptop}{
        width:100%;
    };
    @media ${deviceLessThan.tablet}{
        &::after{
            content: "";
            background:unset;
            background-size: 77px 300px;
            position: absolute;
            bottom: -150px;
            right: 0px;
            height: 300px;
            width: 77px;
            z-index: -1;
        };
        &::before {
            content: "";
            width: 219px;
            border-radius: 50%;
            height: 219px;
            background-color: #eef8fd;
            position: absolute;
            top: 0px;
            left: -70px;
            z-index:-1;
        }
    }
`;

export const PressHeading = styled.div`
    margin: 50px auto 0px;
    width:75%;
    max-width: 1200px;
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 230px 280px;
        position: absolute;
        top: 800px;
        left: -20px;
        height: 280px;
        width: 230px;
        z-index: -1;
    };
    @media ${deviceLessThan.laptop}{
        text-align:left;
        width:95%;
        margin: 50px auto 0px;
    }
    @media ${deviceLessThan.tablet}{
        text-align:center;
        margin: 25px auto 0px;
        &::after{
            top: 500px;
        };
    }
`;

export const PressCardContainer = styled.div`
    box-sizing:border-box;
    width:75%;
    max-width: 1200px;
    height: auto;
    position:relative;
    z-index:0;
    &::after{
        content: "";
        background:url(${props => props.backgroundBefore}) no-repeat;
        background-size: 108px;
        position: absolute;
        top: -61px;
        right: -58px;
        height: 108px;
        width: 108px;
        z-index: -1;
    };
    margin:${props => props.margin ? props.margin : "0px auto"};
    @media ${deviceLessThan.laptop}{
        width:95%;
    }
    @media ${deviceLessThan.tablet}{
        margin:${props => props.sMargin ? props.sMargin :"0px auto"};
        padding:${props => props.sPadding};
        text-align:left;
        width: 100%;
        padding:0px 0px 20px !important;
        &:last-child{
            padding:0px 0px 40px !important;
        }
        &::after{
            content: "";
            background:unset;
            background-size: 301px 136px;
            position: absolute;
            top: 36px;
            right: 10%;
            height: 136px;
            width: 301px;
            z-index: -1;
        };
    }
`;

export const PressFilter = styled.div`
    width:100%;
    padding:0px 0px 40px;
    background:${props => props.background};
    position:relative;
    &::before{
        content:"";
        background:url(${props => props.backgroundBefore}) no-repeat;
        background-size: 270px 105px;
        position: absolute;
        top: 60px;
        left: -165px;
        opacity: 0.3;
        width: 301px;
        height: 136px;
    }
    @media ${deviceLessThan.tablet}{
        &::before{
            content:"";
            background:unset;
            background-size: 270px 105px;
            position: unset;
            top: 60px;
            left: -165px;
            opacity: 0.3;
            width: 301px;
            height: 136px;
        }
    }
`;

export const PressFilterWrapper = styled.div`
    width:75%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    @media ${deviceLessThan.tablet}{
        padding: 26px 0px;
    }
    @media ${deviceLessThan.mobileL}{
        width:100%;
    }
`;

export const PressCardWrapper = styled.div`
    box-sizing:border-box;
    padding:0px 0px 20px;
    &:last-of-type{
        padding:0px 0px 80px;
    }
    height: auto;
    position:relative;
    @media ${deviceLessThan.tablet}{
        padding:${props => props.sPadding};
        text-align:left;
        margin:0 auto;
        width: 95%;
        padding:0px 0px 15px;
        &:last-child{
            padding:0px;
        }
    }
`;

export const PressReleaseContainer = styled.div`
    box-shadow: 0 0 16px 1px rgba(0,0,0,0.1);
    padding: 16px;
    cursor:pointer;
    text-align:${props => props.align};
    background: white;
    height: 100%;
    padding:15px;
    @media ${deviceLessThan.tablet}{
        text-align:unset;
        box-shadow:0 0 16px 0 rgba(0, 0, 0, 0.1);
        border-radius:4px;
        padding: 10px;
    }
`;
