import React, { Fragment, useState, useEffect } from 'react';
import Modal from '../common/Modal/index';
import { ModalContainer, ModalWrapper } from '../common/Modal/Component';
import Layout from '../common/Layout';
import MetaData from '../common/meta/MetaData';
import Heading from '../common/ui/Heading';
import { allMonths, allYears } from '../../constants/appConstant';
import CutomButton from '../common/ui/CutomButton';
import { 
    PressContainer, 
    PressHeading, 
    PressCardContainer, 
    PressFilter,
    PressFilterWrapper
} from './Component'
import { MediaCenterPageLink } from '../News/Component';
import Text from '../common/ui/Text';
import { ImageContainer, Image } from '../common/ui/Image'; 
import PressCard from './PressCard';
import CustomLink from '../common/ui/CustomLink';

const PressRelease = ({ data, title, description, keywords, location }) => {

    const [ toggleModal, setToggleModal ] = useState(false);
    const [ toggleMonthModal, setToggleMonthModal ] = useState(false);
    const [ toggleYearModal, setToggleYearModal ] = useState(false);

    useEffect(() => {
        if (toggleModal || toggleMonthModal || toggleYearModal) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [toggleModal, toggleYearModal, toggleMonthModal]);
    
    const [ top, setTop ] = useState(null);
    const [ left, setLeft ] = useState(null);
    
    const [filter, setFilter] = useState({
        month:null,
        year:null
    });

    let { month, year } = filter;

    const [ years, setYears ] = useState([]);
    const [ months, setMonths ] = useState([]);
    const [ pressList, setPressList ] = useState([]);

    useEffect(() => {
        setPressList(data.pressList);
        setYears(allYears);
        setMonths(allMonths);
    }, []);

    const modalHandler = (name, e) => {
        e.stopPropagation();
        if(e.target.getAttribute("class") && e.target.getAttribute("class").split(" ").includes("Back") === false){
            setToggleModal(!toggleModal);
            if(toggleModal == false){
                let element = document.getElementById(name).getBoundingClientRect();
                setTop(element.top+'px');
                setLeft(element.left+'px');
            }
        }
    }

    const modalMonthHandler = (name, e) => {
        e.stopPropagation();
        if(e.target.getAttribute("class") && e.target.getAttribute("class").split(" ").includes("Back") === false){
            setToggleMonthModal(!toggleMonthModal);
            if(toggleMonthModal == false){
                let element = document.getElementById(name).getBoundingClientRect();
                setTop(element.top+'px');
                setLeft(element.left+'px');
            }
        }
    }

    const modalYearHandler = (name, e) => {
        e.stopPropagation();
        if(e.target.getAttribute("class") && e.target.getAttribute("class").split(" ").includes("Back") === false){
            setToggleYearModal(!toggleYearModal);
            if(toggleYearModal == false){
                let element = document.getElementById(name).getBoundingClientRect();
                setTop(element.top+'px');
                setLeft(element.left+'px');
            }
        }
    }

    const selectYear = name => {
        if(month){
            if(name == year){
                setFilter({
                    ...filter,
                    year:null
                });
                setPressList(data.pressList.filter(el=> el.date.split(" ")[0] == month));
                return;
            }
            setPressList(data.pressList.filter(el=> (el.date.split(" ")[2] == name && el.date.split(" ")[0] == month)));
        }else{
            if(name == year){
                setFilter({
                    ...filter,
                    year:null
                });
                setPressList(data.pressList);
                return;
            }
            setPressList(data.pressList.filter(el=> el.date.split(" ")[2] == name ));
        }
        setFilter({
            ...filter,
            year:name
        });
    }

    const selectMonth = name => {
        if(year){
            if(name == month){
                setFilter({
                    ...filter,
                    month:null
                });
                setPressList(data.pressList.filter(el=> el.date.split(" ")[2] == year));
                return;
            }
            setPressList(data.pressList.filter(el=> (el.date.split(" ")[2] == year && el.date.split(" ")[0] == name)))
        }else{
            if(name == month){
                setFilter({
                    ...filter,
                    month:null
                });
                setPressList(data.pressList);
                return;
            }
            setPressList(data.pressList.filter(el=> el.date.split(" ")[0] == name ))
        }
        setFilter({
            ...filter,
            month:name
        });
    }

    return (
    <Fragment>
        <MetaData 
            data={{}}
            title={title}
            description={description}
            keywords={keywords}
            location={location}
            type="website"
        />
        <Layout>
            <PressContainer
                backgroundBefore="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575448/chqbookWeb/AboutUs/dot-pattern-hero_3x.png"
                backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575448/chqbookWeb/AboutUs/dot-pattern-hero_3x.png"
            >
                <PressHeading
                    backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575403/chqbookWeb/AboutUs/hexagon-no-fill_3x.png"
                >
                    <CutomButton 
                        minwidth="max-content" 
                        swidth="116px"
                        width="131px"
                        backgroundcolor="WHITE" 
                        color="CHQBOOK_BLUE"
                        weight="bold"
                        opacity="0.9"
                        size="xxl"
                        border="1px solid rgba(20, 70, 125, 0.2)"
                        hovercolor="primary"
                        boxshadow="0 4px 8px 0 rgba(0, 0, 0, 0.05)"
                        hoverbackground="WHITE"
                        onClick={modalHandler.bind(null, "pressRelease")}
                        smargin="0 auto"
                        padding="2px 12px 1px"
                        letterspacing="0.2px"
                        borderradius="6px"
                        id="pressRelease"
                    >
                        {data.sectionName}&nbsp;
                    <Image 
                        src={`https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg`} 
                        width="10px" 
                    />
                    </CutomButton>
                    <Modal 
                        show={toggleModal} 
                        modalClosed={modalHandler.bind(null, null)}
                    >
                        <ModalContainer 
                            top={top}
                            left={left}
                            sTop="111px"
                            sLeft="50%"
                            sTransform="translate(-50%, 0%)"
                            transform="translate(0px, 0px)"
                            className="Back"
                        >
                             <CutomButton 
                                width="max-content" 
                                backgroundcolor="WHITE"  
                                color="CHQBOOK_BLUE"
                                size="xxl"
                                weight="bold"
                                hoverbackground="WHITE"
                                border="none"
                                hovercolor="primary"
                                onClick={modalHandler.bind(null, null)}
                                padding="2px 10px"
                                height="auto"
                            >
                                {data.sectionName}&nbsp;
                                <Image 
                                    src={`https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg`}
                                    width="12px" 
                                    style={{ transform:"rotate(180deg)" }}
                                />
                            </CutomButton>
                            <ModalWrapper className="Back">
                                {
                                    data.modalLink.map((el, i) => {
                                        return (
                                            <CustomLink to={el.link} key={i}>
                                                <MediaCenterPageLink>
                                                    {el.text}
                                                </MediaCenterPageLink>   
                                            </CustomLink>
                                        )
                                    })
                                }
                            </ModalWrapper>
                        </ModalContainer>
                    </Modal>
                    <Heading
                        color='MEDIUM_BLACK'
                        maxWidth='max-content'
                        size='xl'
                        weight='normal'
                        lineHeight='1.37'
                        margin="25px 0px"
                        sMargin="20px auto"
                    >
                        {data.heading}
                        <ImageContainer
                            width="210px"
                            sWidth="180px"
                            margin="-20px 0px 0px 0px"
                            sMargin="-10px 0px 0px 0px"
                            xsMargin="-10px auto 0px auto"
                            sdisplay="block"
                        >
                            <Image 
                                src={`https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1602189754/chqbookWeb/0-5kb/red-underline.png`} 
                            />
                        </ImageContainer>
                    </Heading>
                    <Text
                        color='MEDIUM_BLACK'
                        opacity='0.7'
                        size='xl'
                        weight='medium'
                        lineHeight='1.7'
                        letterSpacing='-0.33px'
                        width="max-content"
                        margin="0px 0px 50px"
                        sMargin="0px auto 25px"
                        padding="0px 5vw 0px 0px"
                        sPadding="0px"
                    >
                        {data.description}
                    </Text>
                </PressHeading>
                <PressFilter>
                    <PressFilterWrapper>
                        <CutomButton 
                            minwidth="max-content" 
                            swidth="116px"
                            width="131px"
                            backgroundcolor="WHITE" 
                            color="CHQBOOK_BLUE"
                            weight="medium"
                            opacity="0.9"
                            size="md"
                            border="1px solid #19447a"
                            hovercolor="primary"
                            boxshadow="0 4px 8px 0 rgba(0, 0, 0, 0.05)"
                            hoverbackground="WHITE"
                            margin="0px 10px 0px 0px"
                            padding="2px 12px 1px"
                            letterspacing="0.2px"
                            borderradius="6px"
                            onClick={modalMonthHandler.bind(null, "selectMonth")}
                            id="selectMonth"
                        >
                            {month === null ? "Select Month": month}&nbsp;
                            <Image 
                                src="https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg"
                                width="10px"
                            />
                        </CutomButton>
                        <Modal 
                            show={toggleMonthModal} 
                            modalClosed={modalMonthHandler.bind(null, null)}
                            overflow="scroll"
                        >
                            <ModalContainer 
                                top={top}
                                sTop={top}
                                sLeft={left}
                                left={left}
                                transform="translate(7px, 0px)"
                                mTransform="translate(6px, 0px)"
                                sTransform="translate(0px, 0px)"
                                className="Back"
                                padding="0px"
                                margin="0px 0px 50px"
                                backgroundColor="transparent"
                            >
                                 <CutomButton 
                                        width="max-content" 
                                        minwidth="170px"
                                        margin="0px 0px 2px"
                                        sminwidth="120px"
                                        backgroundcolor="WHITE"  
                                        color="CHQBOOK_BLUE"
                                        size="md"
                                        weight="medium"
                                        height="max-content"
                                        display="block"
                                        justifycontent="space-between"
                                        labelpadding="0px 10px"
                                        hoverbackground="WHITE"
                                        hovercolor="primary"
                                        onClick={modalMonthHandler.bind(null, null)}
                                        padding="8px 0px"
                                        height="auto"
                                        borderradius="4px"
                                    >
                                        {month === null ? "Select Month": month}&nbsp;&nbsp;
                                        <Image 
                                            src="https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg"
                                            width="10px"
                                        />
                                    </CutomButton>
                                <ModalWrapper 
                                    className="Back"
                                    display="block"
                                    margin="0px"
                                    background="white"
                                    borderRadius="4px"
                                >
                                    {months.map((el, i) =>{
                                        return (
                                            <CutomButton 
                                                width="max-content" 
                                                minwidth="170px"
                                                sminwidth="120px"
                                                backgroundcolor="WHITE"  
                                                color="CHQBOOK_BLUE"
                                                size="sm"
                                                weight="medium"
                                                height="max-content"
                                                display="block"
                                                justifycontent="space-between"
                                                labelpadding="0px 10px"
                                                hoverbackground="WHITE"
                                                hovercolor="primary"
                                                onClick={selectMonth.bind(null, el.month)}
                                                padding="8px 0px"
                                                height="auto"
                                                borderradius="4px"
                                                key={el.id}
                                            >
                                                {el.month}&nbsp;
                                                {el.month == month && <Image 
                                                    src="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603979932/chqbookWeb/MediaCenter/check-icon_3x.png"
                                                    width="10px"
                                                />}
                                            </CutomButton>
                                        )
                                    })}
                                </ModalWrapper>
                            </ModalContainer>
                        </Modal>
                        <CutomButton 
                            minwidth="max-content" 
                            swidth="116px"
                            width="131px"
                            backgroundcolor="WHITE" 
                            color="CHQBOOK_BLUE"
                            weight="medium"
                            opacity="0.9"
                            size="md"
                            border="1px solid #19447a"
                            hovercolor="primary"
                            boxshadow="0 4px 8px 0 rgba(0, 0, 0, 0.05)"
                            hoverbackground="WHITE"
                            margin="0px 0px 0px 10px"
                            padding="2px 12px 1px"
                            letterspacing="0.2px"
                            borderradius="6px"
                            onClick={modalYearHandler.bind(null, "selectYear")}
                            id="selectYear"
                        >
                            {year === null ? "Select Year": year}&nbsp;
                            <Image 
                                src="https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg"
                                width="10px"
                            />
                        </CutomButton>
                        <Modal 
                            show={toggleYearModal} 
                            modalClosed={modalYearHandler.bind(null, null)}
                            overflow="scroll"
                        >
                            <ModalContainer 
                                top={top}
                                left={left}
                                sTop={top}
                                sLeft={left}
                                transform="translate(7px, 0px)"
                                mTransform="translate(0px, 0px)"
                                sTransform="translate(0px, 0px)"
                                className="Back"
                                backgroundColor="transparent"
                                margin="0px 0px 50px"
                                padding="0px"
                            >
                                <CutomButton 
                                    width="max-content" 
                                    minwidth="170px"
                                    sminwidth="120px"
                                    margin="0px 0px 2px"
                                    backgroundcolor="WHITE"  
                                    color="CHQBOOK_BLUE"
                                    justifycontent="space-between"
                                    size="md"
                                    weight="normal"
                                    hoverbackground="WHITE"
                                    hovercolor="primary"
                                    onClick={modalYearHandler.bind(null, null)}
                                    labelpadding="0px 10px"
                                    padding="8px 0px"
                                    height="auto"
                                    borderradius="4px"
                                    border="none"
                                >
                                    {year === null ? "Select Year": year}&nbsp;&nbsp;
                                    <Image 
                                        src={`https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg`}
                                        width="12px" 
                                    />
                                </CutomButton>
                                <ModalWrapper 
                                    className="Back"
                                    display="block"
                                    margin="0px"
                                    background="white"
                                    borderRadius="4px"
                                >
                                    {years.map((el, i)=> {
                                        return (
                                            <CutomButton 
                                                width="max-content" 
                                                minwidth="170px"
                                                sminwidth="120px"
                                                backgroundcolor="WHITE"  
                                                color="CHQBOOK_BLUE"
                                                justifycontent="space-between"
                                                size="sm"
                                                display="block"
                                                weight="normal"
                                                hoverbackground="WHITE"
                                                hovercolor="primary"
                                                labelpadding="0px 10px"
                                                onClick={selectYear.bind(null, el.year)}
                                                padding="5px 0px"
                                                height="auto"
                                                borderradius="4px"
                                                key={el.id}
                                            >
                                                {el.year}&nbsp;
                                                {el.year == year && <Image 
                                                    src="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603979932/chqbookWeb/MediaCenter/check-icon_3x.png"
                                                    width="10px"
                                                />}
                                            </CutomButton>
                                        )
                                    })}
                                </ModalWrapper>
                            </ModalContainer>
                        </Modal>
                    </PressFilterWrapper>
                </PressFilter>
                <PressCardContainer
                    backgroundBefore="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575511/chqbookWeb/AboutUs/hexagon-fill_3x.png"
                    margin={"0px auto 50px"}
                >
                    {pressList && pressList.map((el, i) => <PressCard {...el} key={i} />)}
                    {pressList && pressList.length == 0 ? <PressCard data={false} title="No data available" />: ""}
                </PressCardContainer>
            </PressContainer>
        </Layout>
    </Fragment>
    )
}

export default PressRelease;