import React from 'react';
import PressRelease from '../../components/PressRelease'
import { graphql } from 'gatsby';
import { modifyContent } from '../../utils/helpers';

export const query = graphql`
        query{
            content: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#press-release" }}}
                }
            ){
                nodes {
                    plaintext
                    meta_title
                    meta_description
                }
            }
        }
    `;

const PressReleasePage = props => {
    let content = modifyContent(props.data);
    const title = props.data.content?.nodes[0].meta_title;
    const description = props.data.content?.nodes[0].meta_description;

    return (
        <PressRelease
            data={content.content}
            title={title}
            description={description}
            location={props.location}
        />
    )
}

export default PressReleasePage;
