import React from 'react';
import { ModalMain } from './Component';
import Backdrop from './Backdrop/index';

const Modal = (props) => {
    return (
        <ModalMain 
            transform={props.show ? props.show.toString(): undefined} 
            opacity={props.show ? props.show.toString(): undefined}
        >
            <Backdrop 
                show={props.show ? props.show.toString(): undefined} 
                clicked={props.modalClosed} 
                {...props}
            />
        </ModalMain>
    );
};

export default Modal;